import * as React from 'react';

import NextLink from 'next/link';

import { capitalize } from '@effable/misc';
import {
  Box, Container, DisplayOnBrowserMount, Text,
} from '@effable/react';

import { ChangeTheme } from '@/features/change-theme';

import { APP_TITLE } from '@/shared/lib/meta';

export const MainHeader = (): JSX.Element => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      borderBottom="1px solid"
      height="56px"
      borderColor="neutral.neutral5"
    >
      <Container>
        <Box display="flex" paddingY="2x" justifyContent="space-between" alignItems="center" width="100%" gridGap="4x">
          <Text variant="m" component={NextLink} href="/">
            {capitalize(APP_TITLE)}
          </Text>

          <Box>
            <DisplayOnBrowserMount>
              <ChangeTheme />
            </DisplayOnBrowserMount>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
