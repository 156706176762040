import * as React from 'react';

import {
  Box, Container, DisplayOnBrowserMount, Text,
} from '@effable/react';
import Timeago from 'timeago-react';

export const MainFooter = (): JSX.Element => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      borderTop="1px solid"
      borderColor="neutral.neutral5"
      marginTop="auto"
    >
      <Container>
        <Box
          display="flex"
          flexDirection={{ base: 'column', tablet: 'row' }}
          paddingY="32px"
          justifyContent="space-between"
          alignItems={{ base: 'flex-start', tablet: 'center' }}
          width="100%"
          gridGap="4x"
        >
          <Text variant="s" color="text.secondary">
            Copyright © {new Date().getFullYear()} devianllert
          </Text>

          <DisplayOnBrowserMount>
            <Text variant="s" color="text.secondary">
              v{process.env.NEXT_PUBLIC_APP_VERSION}
              {' • '}
              Updated <Timeago datetime={process.env.NEXT_PUBLIC_BUILD_TIME} locale="en" />
            </Text>
          </DisplayOnBrowserMount>
        </Box>
      </Container>
    </Box>
  );
};
